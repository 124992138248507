import { Select, Alert } from "antd";
import { Clock, PlusCircle, Trash2 } from "lucide-react";
import React, { useState } from "react";

const SLADueTime = (props: any) => {
  const conditions = [
    {
      value: "first",
      label: "First response target",
      target: "reminder_response",
    },
    {
      value: "next",
      label: "Next response target",
      target: "reminder_next_response",
    },
    {
      value: "target",
      label: "Resolution target",
      target: "reminder_resolution",
    },
  ];

  const [availableConditions, setAvailableConditions] = useState(conditions);

  const handleAddReminder = () => {
    const nextCondition = availableConditions[0]; // Get the first available condition
    if (nextCondition) {
      props.setRequest((request: any) => ({
        ...request,
        [nextCondition.target]: {
          escalation_time: 30,
          agents: [],
        },
      }));
      setAvailableConditions((prev) =>
        prev.filter((condition) => condition.value !== nextCondition.value)
      );
    }
  };

  const handleConditionChange = (key: string, newValue: string) => {
    const selectedCondition = conditions.find((c) => c.value === newValue);
    if (!selectedCondition) return;

    props.setRequest((request: any) => {
      const updatedRequest = { ...request };
      const oldKey = Object.keys(request).find((k) => k === key);

      if (oldKey) {
        delete updatedRequest[oldKey];
      }

      updatedRequest[selectedCondition.target] = {
        escalation_time: 30,
        agents: [],
      };

      return updatedRequest;
    });

    // Update available conditions dynamically
    setAvailableConditions(
      (prev) =>
        prev
          .filter((c) => c.value !== newValue) // Remove the newly selected value
          .concat(conditions.find((c) => c.target === key) || []) // Add back the old value
    );
  };

  const deleteCondition = (key: string) => {
    props.setRequest((request: any) => {
      const updatedRequest = { ...request };
      delete updatedRequest[key];
      return updatedRequest;
    });

    const deletedCondition = conditions.find((c) => c.target === key);
    if (deletedCondition) {
      setAvailableConditions((prev) => [...prev, deletedCondition]);
    }
  };

  console.log("request", props.request);

  return (
    <div className="mt-5">
      <h6 className="header_text__18">
        Remind agents when the SLA due time approaches
      </h6>
      <div className="mt-2 sla-condition-card-wrapper">
        {Object.keys(props.request).length === 0 ? (
          <div className="sla-no-data-banner p-5 small_text__14">
            <Clock size={16} />
            Set reminder to agents when the SLA due time approaches.
          </div>
        ) : (
          Object.entries(props.request).map(
            ([key, item]: any, index: number) => (
              <div key={key} className={`sla-condition-card p-4 border-bottom`}>
                <div className="d-grid gap-2 w-90">
                  <div className="d-flex align-items-center gap-2">
                    <p className="small_text__14">When</p>
                    <Select
                      value={conditions.find((c) => c.target === key)?.label}
                      onChange={(newValue) =>
                        handleConditionChange(key, newValue)
                      }
                      size="large"
                      placeholder="Choose Condition"
                      options={availableConditions.map((c) => ({
                        value: c.value,
                        label: c.label,
                      }))}
                      className="w-25"
                      disabled={Object.keys(props.request).length === 3}
                    />
                    <p className="small_text__14">approaches in</p>
                    <Select
                      value={props.request[key]?.escalation_time.toString()} // Bind the value to escalation_time
                      onChange={(newValue) => {
                        props.setRequest((request: any) => ({
                          ...request,
                          [key]: {
                            ...request[key],
                            escalation_time: parseInt(newValue, 10), // Update escalation_time
                          },
                        }));
                      }}
                      size="large"
                      placeholder="Select Time"
                      options={[
                        { value: "8", label: "8 hours" },
                        { value: "4", label: "4 hours" },
                        { value: "2", label: "2 hours" },
                        { value: "1", label: "1 hour" },
                        { value: "30", label: "30 minutes" },
                      ]}
                      className="w-25"
                    />
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <p className="small_text__14 text-nowrap">
                      send reminder to
                    </p>
                    <Select
                      mode="multiple"
                      size="large"
                      placeholder="Select one or more values"
                      options={[
                        { value: "jack", label: "Jack" },
                        { value: "lucy", label: "Lucy" },
                        { value: "Yiminghe", label: "Yiminghe" },
                      ]}
                      className="w-100"
                    />
                  </div>
                  <Alert
                    showIcon
                    message="Reminders won't be sent for High, Medium, Low priority tickets."
                    type="warning"
                    className="w-fit-content"
                  />
                </div>
                <Trash2
                  size={18}
                  className="delete-sla-condition"
                  onClick={() => deleteCondition(key)}
                />
                {Object.keys(props.request).length > 1 &&
                  index !== Object.keys(props.request).length - 1 && (
                    <div className="and-condition-button border">AND</div>
                  )}
              </div>
            )
          )
        )}
        <div
          className={` d-flex align-items-center gap-1  px-4 py-3 ${
            Object.keys(props.request).length >= 3
              ? "small_text__15"
              : "cursor-pointer header_blue_text__15"
          }`}
          onClick={handleAddReminder}
        >
          <PlusCircle size={18} /> Add New Reminder
        </div>
      </div>
    </div>
  );
};

export default SLADueTime;
