import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
// import Chart from "react-apexcharts";
import DashboardMoreOptions from "../../components/dashboard-more-options";

function DynamicTunnelChart(props: any) {
  const [chartData, setChartData] = useState<any>({
    series: [],
    options: {},
  });

  useEffect(() => {
    const data = props.graph || [];
    const sortedData: any = [...data].sort((a, b) => b.count - a.count);
    // Transform JSON into series format for ApexCharts (Funnel Chart)
    const series = sortedData.map((item: any) => getValue(item, `count`, 0)); // Y-axis data (counts)
    const categories = sortedData.map(
      (item: any) =>
        `${getValue(item, `key.first_name`, "")} ${getValue(
          item,
          `key.last_name`,
          ""
        )}`
    ); // X-axis categories (names)

    setChartData({
      series: [
        {
          name: "Count",
          data: series,
        },
      ],
      options: {
        chart: {
          type: "bar", // Use bar chart to mimic the funnel/tunnel effect
          // height: 350,
          // toolbar: {
          //   show: false,
          // },
        },
        plotOptions: {
          // bar: {
          //   horizontal: true, // Horizontal bars for funnel style
          //   barHeight: "75%", // Adjust bar height for tunnel effect
          //   distributed: true, // Individual bar colors
          // },
          bar: {
            borderRadius: 0,
            horizontal: true,
            barHeight: "80%",
            isFunnel: true,
            distributed: true,
          },
        },

        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#546E7A",
          "#26a69a",
          "#D10CE8",
        ], // Custom colors
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "12px",
            colors: ["#fff"], // Label colors
          },
        },
        xaxis: {
          categories: categories, // X-axis labels (names)
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
        // title: {
        //   text: props.name || "Tunnel Chart",
        //   align: "center",
        // },
      },
    });
  }, [props.graph]);

  return (
    <div
      className={`${
        getValue(props, "isMaximized", "") ? "" : "dashboard_border"
      }`}
    >
      <p className="header_text__16 pb-3">{props.name || "Tunnel Chart"}</p>
      <div
        className={`${
          getValue(props, "isMaximized", "")
            ? "maximized-chart-container"
            : "chart-holder"
        }`}
      >
        {/* {chartData.series.length > 0 && (
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            // height={350}
          />
        )} */}
      </div>
      {!props.hideOptions && (
        <DashboardMoreOptions
          props={props}
          id={getValue(props, "id", "")}
          confirmDeleteAnalyticsComponent={
            props.confirmDeleteAnalyticsComponent
          }
          handleEditChart={props.handleEditChart}
        />
      )}
    </div>
  );
}

export default DynamicTunnelChart;
