import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import DashboardMoreOptions from "../../components/dashboard-more-options";

// Register the necessary components
ChartJS.register(
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
);

function DynamicLineChartComponent(props: any) {
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });
  const [isStacked, setIsStacked] = useState(false);

  useEffect(() => {
    // Extract the second object in the graph data
    const children = getValue(props, "graph", []);
    if (children && children.length > 0) {
      // If `child` exists, create a stacked line chart
      // const labels = ["Data"]; // Single label for stacked data points
      // const datasets = children.map((child: any, index: number) => ({
      //   label: getValue(child, "key", `Dataset ${index + 1}`),
      //   data: [getValue(child, "count", 0)], // Each child count is a data point in its own dataset
      //   backgroundColor: `rgba(${75 + index * 30}, 192, 192, 0.5)`, // Vary colors for each line
      //   borderColor: `rgba(${75 + index * 30}, 192, 192, 1)`,
      //   fill: true, // Fill area under each line for stacking effect
      // }));

      const datasets = [
        {
          data: children.map((child: any, index: number) =>
            getValue(child, "count", 0)
          ),
          labels: children.map((child: any, index: number) =>
            typeof getValue(child, "key", "") === "object"
              ? `${getValue(child, "key.first_name", "")} ${getValue(
                  child,
                  "key.last_name",
                  ""
                )}`
              : getValue(child, "key", "") ||
                "Unknown" ||
                `Category ${index + 1}`
          ),
          label: "",
          borderWidth: 2,
          fill: false, // Enables the fill for the area
        },
      ];

      // Use unique keys from children as labels
      const labels = children.map((item: any) =>
        Object.keys(getValue(item, "key", "")).length > 0
          ? `${getValue(item, "key.first_name", "")} ${getValue(
              item,
              "key.last_name",
              ""
            )}`
          : getValue(item, "key", "") || "Unknown" || `Category`
      );

      setData({
        labels: labels,
        datasets: datasets,
      });
      // setIsStacked(true); // Enable stacked option
    }
  }, [props.graph, getValue(props, `module_field.label`, "")]);
  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          color: "rgba(200, 200, 200, 0.2)", // Gridline color
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div
      className={`${
        getValue(props, "isMaximized", "") ? "" : "dashboard_border"
      }`}
    >
      <p
        className="header_text__16 pb-3 cursor-pointer"
        onClick={() => props.toggleChartDetails(props)}
      >
        {getValue(props, "name", "")}
      </p>
      <div
        className={`${
          getValue(props, "isMaximized", "")
            ? "maximized-chart-container"
            : "chart-holder"
        }`}
      >
        <Line data={data} options={options} />
      </div>
      {!props.hideOptions && (
        <DashboardMoreOptions
          props={props}
          id={getValue(props, "id", "")}
          confirmDeleteAnalyticsComponent={
            props.confirmDeleteAnalyticsComponent
          }
          handleEditChart={props.handleEditChart}
        />
      )}
    </div>
  );
}

export default DynamicLineChartComponent;
