import React, { useState, useRef, useEffect } from "react";
import { getValue } from "@utils/lodash";

const CustomTimeInputToggle = ({
  onTimeChange,
  request,
  current,
  timeKey,
}: {
  onTimeChange: (time: string) => void;
  request: any;
  current: string;
  timeKey: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempTime, setTempTime] = useState({ days: 0, hours: 0, minutes: 0 });
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const timeValue = getValue(request, `${current}.${timeKey}`, "/PT0M/");
    const parsedTime = parseISO8601(timeValue);
    setTempTime(parsedTime);
  }, [request, current, timeKey]);

  const handleToggleEdit = () => {
    setIsEditing(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!containerRef.current?.contains(e.relatedTarget)) {
      setIsEditing(false);
      const formattedTime = convertToISO8601(tempTime);
      onTimeChange(formattedTime);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTempTime((prev) => {
      const newTime = {
        ...prev,
        [name]: parseInt(value, 10) || 0,
      };
      return newTime;
    });
  };

  const convertToISO8601 = (tempTime: {
    days: number;
    hours: number;
    minutes: number;
  }) => {
    let duration = "P";
    if (tempTime.days) duration += `${tempTime.days}D`;
    if (tempTime.hours || tempTime.minutes)
      duration += `T${tempTime.hours}H${tempTime.minutes}M`;
    return duration;
  };

  const parseISO8601 = (duration: string) => {
    const regex = /P(?:(\d+)D)?(?:T(\d+)H)?(?:(\d+)M)?/;
    const match = duration.match(regex);

    if (!match) {
      return { days: 0, hours: 0, minutes: 0 };
    }

    return {
      days: match?.[1] ? parseInt(match[1], 10) : 0,
      hours: match?.[2] ? parseInt(match[2], 10) : 0,
      minutes: match?.[3] ? parseInt(match[3], 10) : 0,
    };
  };

  const handleDisplayTime = () => {
    if (isEditing) {
      const timeParts = [];

      if (tempTime.days > 0) timeParts.push(`${tempTime.days}d`);
      if (tempTime.hours > 0) timeParts.push(`${tempTime.hours}h`);
      if (tempTime.minutes > 0) timeParts.push(`${tempTime.minutes}m`);

      return timeParts.join(" ");
    }

    const timeValue = getValue(request, `${current}.${timeKey}`, "/PT0M/");
    const parsedTime = parseISO8601(timeValue);

    const timeParts = [];

    if (parsedTime.days > 0) timeParts.push(`${parsedTime.days}d`);
    if (parsedTime.hours > 0) timeParts.push(`${parsedTime.hours}h`);
    if (parsedTime.minutes > 0) timeParts.push(`${parsedTime.minutes}m`);

    if (timeParts.length === 0) {
      return <p className="small_text__14 no-time-error">Enter time span</p>;
    }

    return timeParts.join(" ");
  };

  return (
    <div
      className="custom-time-input-container"
      onClick={handleToggleEdit}
      ref={containerRef}
      tabIndex={0}
      onBlur={handleBlur}
    >
      {isEditing ? (
        <div className="d-flex gap-2">
          <div>
            <input
              type="number"
              name="days"
              value={getValue(tempTime, "days", 0)}
              onChange={handleChange}
              placeholder="Days"
              autoFocus
              className="sla-time-input"
            />
            <p className="small_text__14 text-center">day</p>
          </div>
          <div>
            <input
              type="number"
              name="hours"
              value={getValue(tempTime, "hours", 0)}
              onChange={handleChange}
              placeholder="Hours"
              className="sla-time-input"
            />
            <p className="small_text__14 text-center">hrs</p>
          </div>
          <div>
            <input
              type="number"
              name="minutes"
              value={getValue(tempTime, "minutes", 0)}
              onChange={handleChange}
              placeholder="Minutes"
              className="sla-time-input"
            />
            <p className="small_text__14 text-center">mins</p>
          </div>
        </div>
      ) : (
        <div>
          <div className="time-input-data">{handleDisplayTime()}</div>
        </div>
      )}
    </div>
  );
};

export default CustomTimeInputToggle;
