import UrlSvgComponent from "@assets/svg/custom/url";
import CallSvgComponent from "@assets/svg/phone";
import ReplySvgComponent from "@assets/svg/whatsapp-reply";
import { replacePlaceholders } from "@common/markdown/markdown-helper";
import {
  capitalizeFirstLetterOfEachWord,
  concatString,
} from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Button, Empty, Radio, Tag, Tooltip, Typography } from "antd";
import { config } from "env";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { convertCurrentDate } from "@common/date-helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./backendTemplate.scss";

const StaticTemplateList = ({ templateData, params }: any) => {
  const navigate = useNavigate();

  return (
    <div className="backend-template-wrapper">
      {templateData.map((item: any, index: any) => (
        <div className="backend-template-container" key={index}>
          <div className="d-flex justify-content-between gap-3 align-items-start bg-white p-3 rounded-top-3 ">
            <div className="">
              <h6 className="header_text__16 mb-2 position-relative pe-4">
                <Tooltip
                  title={capitalizeFirstLetterOfEachWord(
                    getValue(item, "name", "")
                  )}
                >
                  {concatString(
                    capitalizeFirstLetterOfEachWord(getValue(item, "name", "")),
                    26
                  )}
                </Tooltip>
              </h6>
              <Tag color="green">{getValue(item, "category", "")}</Tag>{" "}
            </div>
            <Button
              type="primary"
              onClick={() =>
                navigate(
                  `/${getValue(
                    params,
                    "orgId",
                    ""
                  )}/broadcast/template/${getValue(item, "id", "")}`
                )
              }
            >
              Use
            </Button>
          </div>
          <div className="template-message-container py-4">
            <div className="mx-4 bg-white p-3 template-message-body">
              <MarkdownPreview
                source={replacePlaceholders(getValue(item, `body`, ""))}
                className="mb-3"
              />
              {getValue(item, `header_type`, "") === "IMAGE" &&
                getValue(item, `header_file`, "") && (
                  <div className="d-flex justify-content-center align-items-center mb-2">
                    <img
                      src={`https://${config.URL}/${getValue(
                        item,
                        `header_file.key`,
                        ""
                      )}`}
                      alt="Selected"
                      className="w-100 object-fit-cover rounded-3"
                      height={120}
                    />
                  </div>
                )}
              {getValue(item, `header_type`, "") === "VIDEO" &&
                getValue(item, `header_file`, "") && (
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <video
                      autoPlay
                      loop
                      controls
                      style={{ height: "100px", width: "100%" }}
                    >
                      <source
                        src={`https://${config.URL}/${getValue(
                          item,
                          `header_file.key`,
                          ""
                        )}`}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                )}
              {getValue(item, `header_type`, "") === "DOCUMENT" && (
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src="/images/icons/document-blue.svg"
                    className="dummy-image-icon"
                    alt=""
                  />
                </div>
              )}
              {getValue(item, `buttons`, []) &&
                getValue(item, "buttons", []).map(
                  (button: any, btnIndex: number) => (
                    <div key={btnIndex} className="">
                      <div className="d-flex justify-content-center align-items-center gap-2 broadcast_button">
                        {getValue(button, "type", "") === "QUICK_REPLY" ? (
                          <ReplySvgComponent color="#408dfb" size={16} />
                        ) : getValue(button, "type", "") ===
                          "CALL_TO_ACTION" ? (
                          <CallSvgComponent color="#408dfb" size={14} />
                        ) : getValue(button, "type", "") === "URL" ? (
                          <UrlSvgComponent color="#408dfb" size={14} />
                        ) : null}

                        <p className="small_text__13 text-black py-1">
                          {getValue(button, "text", "")}
                        </p>
                      </div>
                    </div>
                  )
                )}
            </div>
            {/* {getValue(item, "category", "").length > 0 && (
                        <>
                          <div className="broadcast-carousel-swiper_wrapper mx-4 mt-2">
                            <Swiper
                              spaceBetween={10}
                              slidesPerView={1.25}
                              onSlideChange={() => console.log("slide change")}
                              modules={[Navigation]}
                              navigation={{
                                prevEl: ".custom-button-prev",
                                nextEl: ".custom-button-next",
                              }}
                              ref={swiperRef}
                              className="position-relative"
                            >
                              {getValue(item, "carousels", "").map(
                                (carousel: any, index: number) => (
                                  <SwiperSlide key={index}>
                                    <div className="carousel-swiper-card">
                                      {getValue(carousel, "header_type", "") ===
                                      "IMAGE" ? (
                                        <img
                                          src={`https://${
                                            config.URL
                                          }/${getValue(
                                            carousel,
                                            "header_file.key",
                                            ""
                                          )}`}
                                          alt="Carousel"
                                          className="broadcast-carousel-image"
                                        />
                                      ) : (
                                        <video
                                          autoPlay
                                          className="broadcast-carousel-video"
                                        >
                                          <source
                                            src={`https://${
                                              config.URL
                                            }/${getValue(
                                              carousel,
                                              "header_file.key",
                                              ""
                                            )}`}
                                            type="video/mp4"
                                          />
                                        </video>
                                      )}
                                      <p className="p-2 small_text__13 text-black">
                                        {getValue(carousel, "body", "")}
                                      </p>
                                      <div
                                        className={`ms-2 d-flex flex-column gap-1`}
                                      >
                                        {getValue(carousel, "buttons", []).map(
                                          (button: any, btnIndex: number) => (
                                            <div key={btnIndex}>
                                              <div className="d-flex justify-content-center align-items-center broadcast_button">
                                                {getValue(
                                                  button,
                                                  "type",
                                                  ""
                                                ) === "QUICK_REPLY" ? (
                                                  <ReplySvgComponent
                                                    color="#408dfb"
                                                    size={16}
                                                  />
                                                ) : getValue(
                                                    button,
                                                    "type",
                                                    ""
                                                  ) === "CALL_TO_ACTION" ? (
                                                  <CallSvgComponent
                                                    color="#408dfb"
                                                    size={14}
                                                  />
                                                ) : getValue(
                                                    button,
                                                    "type",
                                                    ""
                                                  ) === "URL" ? (
                                                  <UrlSvgComponent
                                                    color="#408dfb"
                                                    size={14}
                                                  />
                                                ) : null}

                                                <p className="ms-2 small_text__12 text-black">
                                                  {getValue(button, "text", "")}
                                                </p>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                )
                              )}
                            </Swiper>
                          </div>
                        </>
                      )} */}
          </div>
          <div className="d-flex align-items-center justify-content-between p-3 bg-white rounded-bottom-3">
            <Tag bordered={false} color="blue">
              {capitalizeFirstLetterOfEachWord(getValue(item, "language", ""))}
            </Tag>
            <p className="small_text__13">
              {convertCurrentDate(getValue(item, "created_at", ""))}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StaticTemplateList;
