import { Button, Drawer, Radio } from "antd";
import React, { useEffect, useState } from "react";
import {
  DashboardDateGranularityList,
  DashboardGraphTypeList,
  DashboardGroupingList,
  DashboardKPIMetricCondition,
  DashboardKPIMetricCountCondition,
  DashboardShowRankChartList,
} from "../helpers/enum";
import { getValue } from "@utils/lodash";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import CriteriaFilters from "../components/criteria-filters";
import ChartCustomSubmenuDropdown from "../components/chart-custom-submenu-dropdown";

function ComponentChartDrawer(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const {
    request,
    setRequest,
    getAllPipelines,
    allPipelines,
    setNewModule,
    newModule,
    allModules,
    getSpecificPipelineInfo,
    dateColumnFields,
    isLoading,
    handleSubmit,
    onClose,
    showChartStyleComponentDrawer,
    formColumnFields,
    workflowConditions,
    workflowOptions,
    hideValidation,
    simpleValidator,
    resetAnalyticRequest,
    setAnalyticsEditId,
  } = props;
  const findName = (name: string) => {
    let info: any = DashboardGraphTypeList.find(
      (item: object) => getValue(item, `value`, "") === name
    );
    return getValue(info, `label`, "");
  };

  const handleChangeStyle = () => {
    resetAnalyticRequest();
    setStackedChart(false);
    onClose();
    props.hideValidation();
    showChartStyleComponentDrawer();
    setAnalyticsEditId("");
    props.setStackedChart(false);
  };

  const findGroupLabel = () => {
    switch (getValue(request, `graph_type`, "")) {
      case "donut_chart":
      case "pie_chart":
        return "(Slices)";
      case "column_chart":
      case "bar_chart":
      case "line_chart":
        return "1 (X-Axis)";
      case "table_chart":
        return "(1st Column)";
      case "funnel_chart":
        return "(Stages)";
      case "area_chart":
      case "heat_map":
        return "1 (Y-Axis)";
      case "line_chart":
        return "(X-Axis)";
      default:
        return "";
    }
  };

  const findMeasureLabel = () => {
    switch (getValue(request, `graph_type`, "")) {
      case "donut_chart":
      case "pie_chart":
        return "Measure";
      case "column_chart":
      case "bar_chart":
      case "line_chart":
      case "heat_map":
      case "funnel_chart":
      case "line_chart":
        return "Measure of (Y-Axis)";
      case "table_chart":
        return "Measure (2nd column)";
      case "area_chart":
        return "Grouping 1 (y-axis)";
      default:
        return "";
    }
  };

  const findStackedGroupLabel = () => {
    switch (getValue(request, `graph_type`, "")) {
      case "donut_chart":
      case "pie_chart":
        return "(Slices)";
      case "column_chart":
      case "bar_chart":
      case "line_chart":
        return "2 (Stack)";
      case "funnel_chart":
        return "(Stages)";
      case "area_chart":
      case "heat_map":
        return "2";
      case "line_chart":
        return "(X-Axis)";
      default:
        return "";
    }
  };
  useEffect(() => {
    if (getValue(props, `stackedChart`, false)) {
      setStackedChart(getValue(props, `stackedChart`, false));
    }
  }, [getValue(props, `stackedChart`, false)]);

  const [stackedChart, setStackedChart] = useState(false);

  useEffect(() => {
    if (
      getValue(request, `graph_type`, "") === "area_chart" ||
      getValue(request, `graph_type`, "") === "heat_map"
    ) {
      setStackedChart(true);
    }
  }, [
    getValue(request, `graph_type`, "") === "area_chart",
    getValue(request, `graph_type`, "") === "heat_map",
  ]);

  const [isXAxisOpen, setIsXAxisOpen] = useState(false);

  const handleXAxisOpenChange = (open: boolean) => {
    setIsXAxisOpen(open);
  };
  const [selectedXAxisField, setSelectXAxisField] = useState("");

  const handleClearXAxis = () => {
    setRequest((prev: any) => {
      return {
        ...prev,
        module_field: "",
        date_granularity: "",
      };
    });
    setSelectXAxisField("");
  };

  const [isYAxisOpen, setIsYAxisOpen] = useState(false);

  const handleYAxisOpenChange = (open: boolean) => {
    setIsYAxisOpen(open);
  };
  const [selectedYAxisField, setSelectYAxisField] = useState("");

  const handleClearYAxis = () => {
    setRequest((prev: any) => {
      return {
        ...prev,
        child_module_field: "",
        child_date_granularity: "",
      };
    });
    setSelectYAxisField("");
  };

  const getSelectedDashboard = () => {
    const dashboards = getValue(props, `allDashboardList`, []);
    const analyticsId = getValue(UrlParams, `analyticsId`, "");

    if (dashboards.length > 0) {
      return analyticsId
        ? dashboards.find(
            (item: any) => getValue(item, `id`, "") === analyticsId
          ) || {}
        : dashboards[0];
    }

    return {}; // Return an empty object if no dashboards exist.
  };
  return (
    <Drawer
      title={findName(props.selectedComponentOption)}
      onClose={() => {
        props.onClose();
        props.hideValidation();
        setAnalyticsEditId("");
        props.setStackedChart(false);
      }}
      open={props.open}
      width={1000}
      className="dashboard-slider-container-footer"
    >
      <div className="d-flex justify-content-between gap-3">
        {DashboardGraphTypeList.map(
          (item: object, index: number) =>
            getValue(item, "value", "") === props.selectedComponentOption && (
              <div>
                <div className="dashboard_charttype p-2">
                  <img
                    src={getValue(item, "image", "")}
                    alt={getValue(item, `label`, "")}
                    className="w-100"
                  />
                </div>
                <p
                  className="cursor-pointer header_blue_text__16 pt-2 text-center"
                  onClick={() => handleChangeStyle()}
                >
                  Change Style
                </p>
              </div>
            )
        )}
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center">
            {getValue(request, `graph_type`, "") !== "donut_chart" &&
              getValue(request, `graph_type`, "") !== "pie_chart" &&
              getValue(request, `graph_type`, "") !== "table_chart" &&
              getValue(request, `graph_type`, "") !== "funnel_chart" &&
              getValue(request, `graph_type`, "") !== "area_chart" &&
              getValue(request, `graph_type`, "") !== "line_chart" &&
              getValue(request, `graph_type`, "") !== "heat_map" && (
                <Radio.Group
                  value={stackedChart ? "stacked" : "bar"}
                  onChange={(e) => {
                    const isStacked = e.target.value === "stacked";
                    setRequest((prevState: any) => ({
                      ...prevState,
                      is_child: isStacked,
                      child_module_field: "",
                      child_date_granularity: "",
                    }));
                    setStackedChart(isStacked);
                  }}
                  buttonStyle="solid"
                  className="mb-3"
                >
                  <Radio.Button value="bar">Bar Chart</Radio.Button>
                  <Radio.Button value="stacked">Stacked Chart</Radio.Button>
                </Radio.Group>
              )}
          </div>
          <div className="d-grid gap-3 w-100">
            <div className="db-charttype-creator">
              <h6 className="header_text__20  ">
                Component ({getValue(getSelectedDashboard(), `label`, "")})
              </h6>
              <div className="">
                <div className="mb-3">
                  <InputRuleForm
                    inputType="TEXT"
                    label="Name"
                    placeholder="Enter Component Name"
                    name="name"
                    value={getValue(request, `name`, "")}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        name: e.target.value,
                      })
                    }
                    validator={simpleValidator}
                    required
                  />
                </div>
                <div className="d-flex align-items-center gap-3 ">
                  {getValue(getSelectedDashboard(), `name`, "") !== "Leads" &&
                    getValue(getSelectedDashboard(), `name`, "") !==
                      "Contacts" &&
                    getValue(getSelectedDashboard(), `name`, "") !==
                      "Companies" &&
                    getValue(getSelectedDashboard(), `name`, "") !== "Tasks" &&
                    getValue(getSelectedDashboard(), `name`, "") !==
                      "Deals" && (
                      <div className="mb-3 w-50">
                        <SearchToolTip
                          name="Module"
                          data={getValue(props, `allModules`, [])}
                          label="label"
                          selectKey="id"
                          value={getValue(request, `module`, "")}
                          onChange={(e: any) => {
                            getAllPipelines(
                              getValue(e, `api_name`, ""),
                              allModules
                            );
                            setNewModule(getValue(e, `api_name`, ""));
                            setRequest({
                              ...request,
                              module: getValue(e, `id`, ""),
                              pipeline: "",
                            });
                            props.hideValidation();
                          }}
                          validator={simpleValidator}
                          required
                        />
                      </div>
                    )}
                  {getValue(getSelectedDashboard(), `name`, "") !== "Tasks" &&
                    ["deals", "leads"].includes(
                      getValue(props, "newModule", "")
                    ) && (
                      <div className="mb-4">
                        <SearchToolTip
                          name="Pipeline"
                          label="label"
                          selectKey="id"
                          value={getValue(request, `pipeline`, "")}
                          data={allPipelines}
                          onChange={(e: any) => {
                            getSpecificPipelineInfo(getValue(e, `id`, ""));
                            setRequest({
                              ...request,
                              pipeline: getValue(e, `id`, ""),
                            });
                            props.hideValidation();
                          }}
                          validator={simpleValidator}
                          required
                        />
                      </div>
                    )}
                </div>
                <div className="align-items-center w-100 d-flex gap-3">
                  <div className="mb-3">
                    <ChartCustomSubmenuDropdown
                      label={`Grouping ${findGroupLabel()}`}
                      placeholder={`Enter Grouping ${findGroupLabel()}`}
                      selectedSubMenu={getValue(
                        request,
                        "date_granularity",
                        ""
                      )}
                      request={request}
                      setRequest={setRequest}
                      isAntOpen={isXAxisOpen}
                      mainMenu={dateColumnFields}
                      formFields={formColumnFields}
                      subMenu={DashboardDateGranularityList}
                      selectedField={selectedXAxisField}
                      setSelectedField={setSelectXAxisField}
                      handleAntOpenChange={handleXAxisOpenChange}
                      handleClear={handleClearXAxis}
                      dynamicModuleField="module_field"
                      dynamicDateGranularity="date_granularity"
                      validator={simpleValidator}
                      required
                    />
                  </div>
                  <div className="mb-4 w-50">
                    <SearchToolTip
                      name={findMeasureLabel()}
                      label="label"
                      selectKey="value"
                      value={getValue(request, `metric`, "")}
                      data={
                        newModule === "deals" ||
                        newModule === "leads" ||
                        newModule === "calls"
                          ? DashboardKPIMetricCondition
                          : DashboardKPIMetricCountCondition
                      }
                      onChange={(e: any) => {
                        setRequest({
                          ...request,
                          metric: getValue(e, `value`, ""),
                        });
                      }}
                      validator={simpleValidator}
                      required
                    />
                  </div>
                </div>
                {stackedChart && (
                  <div className="">
                    <ChartCustomSubmenuDropdown
                      label={`Grouping ${findStackedGroupLabel()}`}
                      placeholder={`Enter Grouping ${findStackedGroupLabel()}`}
                      selectedSubMenu={getValue(
                        request,
                        "child_date_granularity",
                        ""
                      )}
                      request={request}
                      setRequest={setRequest}
                      isAntOpen={isYAxisOpen}
                      mainMenu={dateColumnFields}
                      formFields={formColumnFields}
                      subMenu={DashboardDateGranularityList}
                      selectedField={selectedYAxisField}
                      setSelectedField={setSelectYAxisField}
                      handleAntOpenChange={handleYAxisOpenChange}
                      handleClear={handleClearYAxis}
                      dynamicModuleField="child_module_field"
                      dynamicDateGranularity="child_date_granularity"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="db-charttype-creator">
              <CriteriaFilters
                request={request}
                setRequest={setRequest}
                workflowConditions={workflowConditions}
                workflowOptions={workflowOptions}
              />
            </div>

            <div className="db-charttype-creator">
              <h6 className="header_text__20">More Options</h6>
              <div className="mb-3 w-50">
                <SearchToolTip
                  name="Sort By"
                  data={DashboardShowRankChartList}
                  label="label"
                  selectKey="value"
                  value={getValue(request, `show_rank`, "")}
                  onChange={(e: any) =>
                    setRequest({
                      ...request,
                      show_rank: getValue(e, `value`, ""),
                    })
                  }
                  validator={simpleValidator}
                />
              </div>
              <div className="mb-3 w-50">
                <SearchToolTip
                  name="Maximum grouping"
                  placeholder="Enter Maximum grouping"
                  selectKey="value"
                  data={DashboardGroupingList}
                  label="label"
                  value={getValue(request, `maximum_grouping`, "")}
                  onChange={(e: any) =>
                    setRequest({
                      ...request,
                      maximum_grouping: getValue(e, `value`, ""),
                    })
                  }
                  validator={simpleValidator}
                />
              </div>
              {/* <div className="d-flex align-items-center">
                <Checkbox
                  checked={getValue(request, `is_benchmark`, false)}
                  onChange={(e) =>
                    setRequest({
                      ...request,
                      is_benchmark: !getValue(request, `is_benchmark`, false),
                    })
                  }
                />
                <label className="form-label ms-3">Benchmark</label>
              </div>
              {getValue(request, `is_benchmark`, false) &&
                getValue(request, `graph_type`, "") !== "donut_chart" &&
                getValue(request, `graph_type`, "") !== "pie_chart" &&
                getValue(request, `graph_type`, "") !== "table_chart" &&
                getValue(request, `graph_type`, "") !== "funnel_chart" &&
                getValue(request, `graph_type`, "") !== "heat_map" && (
                  <div className="mb-3 w-50">
                    <InputRuleForm
                      inputType="NUMBER"
                      label="Benchmark for y-axis"
                      placeholder="Enter Benchmark for y-axis"
                      name="benchmark"
                      value={getValue(request, `benchmark`, "")}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          benchmark: e.target.value,
                        })
                      }
                    />
                  </div>
                )} */}
            </div>
          </div>
        </div>
      </div>

      <div className="text-right mt-2 ant-slider-custom-footer gap-2">
        <Button
          size="large"
          onClick={() => {
            props.onClose();
            props.hideValidation();
            resetAnalyticRequest();
            setAnalyticsEditId("");
            props.setStackedChart(false);
          }}
        >
          Cancel
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={() => {
            handleSubmit();
            props.hideValidation();
          }}
          loading={isLoading}
          disabled={isLoading}
        >
          Submit
        </Button>
      </div>
    </Drawer>
  );
}

export default ComponentChartDrawer;
