import { handleSelectArrayChange } from "@common/handleChange";
import { getValue } from "@utils/lodash";
import { Alert, Select } from "antd";
import { PlusCircle, Trash2 } from "lucide-react";
import React from "react";

const SLATicketCondition = (props: any) => {
  const slaConditionLength = getValue(
    props,
    "request.sla_conditions.length",
    0
  );

  const handleSelectCondition = (index: number, newValues: string[]) => {
    props.setRequest((prevRequest: any) => {
      return {
        ...prevRequest,
        sla_conditions: prevRequest.sla_conditions.map(
          (condition: any, idx: number) => {
            if (idx !== index) return condition;
            return {
              ...condition,
              value: [...new Set([...condition.value, ...newValues])],
            };
          }
        ),
      };
    });
  };

  const handleDeselectCondition = (value: string, idx: number) => {
    props.setRequest((prevRequest: any) => {
      return {
        ...prevRequest,
        sla_conditions: prevRequest.sla_conditions.map(
          (condition: any, index: number) => {
            if (index !== idx) return condition;
            return {
              ...condition,
              value: condition.value.filter((item: string) => item !== value),
            };
          }
        ),
      };
    });
  };

  return (
    <div className="mt-5">
      <h6 className="header_text__18">
        When a ticket satisfies these conditions:
      </h6>
      <div className="mt-2 sla-condition-card-wrapper">
        {getValue(props, "request.sla_conditions", []).map(
          (condition: any, index: any) => (
            <div className={`sla-condition-card p-4 border-bottom`} key={index}>
              <div className="d-grid gap-2 w-90">
                <Select
                  // prefix="If"
                  // defaultValue={["lucy"]}
                  onChange={(value) => {
                    handleSelectArrayChange(
                      index,
                      { value },
                      "module_field",
                      "sla_conditions",
                      props.setRequest
                    );
                    console.log("value", value);
                  }}
                  value={getValue(condition, `module_field`, "")}
                  placeholder="Choose Condition"
                  options={getValue(props, "moduleFields", [])}
                  className="w-25"
                />
                {getValue(condition, `module_field`, "") && (
                  <Select
                    mode="multiple"
                    size="large"
                    value={getValue(condition, `value`, [])}
                    allowClear
                    onChange={(newValues) =>
                      handleSelectCondition(index, newValues)
                    }
                    onDeselect={(value) =>
                      handleDeselectCondition(value, index)
                    }
                    placeholder="Select one or more values"
                    options={[
                      { value: "jack", label: "Jack" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "yiminghe" },
                    ]}
                  />
                )}
              </div>
              {slaConditionLength > 1 && (
                <Trash2
                  size={18}
                  className="delete-sla-condition"
                  onClick={() => props.deleteConditionArray(index)}
                />
              )}
              {slaConditionLength > 1 && index !== slaConditionLength - 1 && (
                <div className="and-condition-button border">AND</div>
              )}
            </div>
          )
        )}
        <div
          className="header_blue_text__15 d-flex align-items-center gap-1 cursor-pointer px-4 py-3"
          onClick={() => props.handleAddCondition()}
        >
          <PlusCircle size={18} /> Add Condition
        </div>
      </div>
      <Alert
        showIcon
        type="error"
        message="Please choose at least one condition."
        className="w-fit-content mt-2"
      />
    </div>
  );
};

export default SLATicketCondition;
