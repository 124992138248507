import { Badge, Select, Switch, Table, TableProps } from "antd";
import { getValue } from "@utils/lodash";
import CustomTimeInputToggle from "./CustomTimeInputToggle";

const SLATargetTable = (props: any) => {
  const handleChangeOperationalHours = (index: number, value: string) => {
    props.setRequest((prev: any) => ({
      ...prev,
      [`priority_${index + 1}`]: {
        ...prev[`priority_${index + 1}`],
        business_hours: value,
      },
    }));
  };

  const handleChangeEscalation = (index: number, checked: boolean) => {
    props.setRequest((prev: any) => ({
      ...prev,
      [`priority_${index + 1}`]: {
        ...prev[`priority_${index + 1}`],
        escalation_enabled: checked,
      },
    }));
  };

  const handleTimeChange = (index: number, time: string, timeKey: string) => {
    props.setRequest((prev: any) => ({
      ...prev,
      [`priority_${index + 1}`]: {
        ...prev[`priority_${index + 1}`],
        [timeKey]: time,
      },
    }));
  };

  const columns: TableProps["columns"] = [
    {
      title: "PRIORITY",
      dataIndex: "priority",
      key: "priority",
      render: (text: any, item: any) => (
        <div
          className="d-flex align-items-center gap-2 small_text__16"
          style={{ height: "60px" }}
        >
          <Badge status={item.color} />
          {text}
        </div>
      ),
    },
    {
      title: "FIRST RESPONSE TIME",
      dataIndex: "first_response_time",
      key: "first_response_time",
      render: (_, record, index) => (
        <CustomTimeInputToggle
          onTimeChange={(time: any) =>
            handleTimeChange(index, time, "first_response_time")
          }
          request={props.request}
          current={`priority_${index + 1}`}
          timeKey="first_response_time"
        />
      ),
    },
    {
      title: "EVERY RESPONSE TIME",
      dataIndex: "every_response_time",
      key: "every_response_time",
      render: (_, record, index) => (
        <CustomTimeInputToggle
          onTimeChange={(time: any) =>
            handleTimeChange(index, time, "every_response_time")
          }
          request={props.request}
          current={`priority_${index + 1}`}
          timeKey="every_response_time"
        />
      ),
    },
    {
      title: "RESOLUTION TIME",
      key: "resolution_due_time",
      dataIndex: "resolution_due_time",
      render: (_, record, index) => (
        <CustomTimeInputToggle
          onTimeChange={(time: any) =>
            handleTimeChange(index, time, "resolution_due_time")
          }
          request={props.request}
          current={`priority_${index + 1}`}
          timeKey="resolution_due_time"
        />
      ),
    },
    {
      title: "OPERATIONAL HOURS",
      key: "hours",
      render: (_, record, index) => (
        <Select
          defaultValue="business_hours"
          onChange={(value) => handleChangeOperationalHours(index, value)}
          value={getValue(
            props,
            `request.priority_${index + 1}.business_hours`,
            false
          )}
          placeholder="Choose Condition"
          options={[
            { value: true, label: "Business Hours" },
            { value: false, label: "Calendar Hours" },
          ]}
        />
      ),
    },
    {
      title: "ESCALATION",
      key: "escalation",
      render: (_, record, index) => (
        <Switch
          size="small"
          checked={getValue(
            props,
            `request.priority_${index + 1}.escalation_enabled`,
            false
          )}
          onChange={(checked) => handleChangeEscalation(index, checked)}
        />
      ),
    },
  ];

  const tableData = [
    {
      priority: "Urgent",
      color: "error",
    },
    {
      priority: "High",
      color: "warning",
    },
    {
      priority: "Medium",
      color: "processing",
    },
    {
      priority: "Low",
      color: "success",
    },
  ];

  return (
    <div className="mt-5">
      <h6 className="header_text__18">Set SLA target as:</h6>
      <Table
        columns={columns}
        dataSource={tableData}
        className="mt-2"
        pagination={false}
      />
    </div>
  );
};

export default SLATargetTable;
