import { getValue } from "@utils/lodash";
import React from "react";
import DashboardMoreOptions from "../../components/dashboard-more-options";

function KPIBasicComponent(props: any) {
  return (
    <div
      className={`${
        getValue(props, "isMaximized", "") ? "" : "dashboard_border"
      }`}
    >
      <p
        className="header_text__16 pb-3 cursor-pointer"
        onClick={() => props.toggleChartDetails(props)}
      >
        {getValue(props, `name`, "")}
      </p>
      <div className="d-flex align-items-center">
        <h6 className="standard-highlight ms-3">
          {getValue(props, `graph.current`, "")}
        </h6>
      </div>
      <DashboardMoreOptions
        props={props}
        id={getValue(props, "id", "")}
        confirmDeleteAnalyticsComponent={props.confirmDeleteAnalyticsComponent}
        handleEditChart={props.handleEditChart}
      />
    </div>
  );
}

export default KPIBasicComponent;
