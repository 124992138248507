import { getValue } from "@utils/lodash";
import { Alert, Select } from "antd";
import { PlusCircle, Trash2 } from "lucide-react";

const SLAViolationCondition = (props: any) => {
  return (
    <div className="mt-5">
      <h6 className="header_text__18">
        Send escalation when the SLA is violated
      </h6>
      <div className="mt-2 sla-condition-card-wrapper">
        {getValue(props, "request.sla_conditions", []).map(
          (condition: any, index: any) => (
            <div className={`sla-condition-card p-4 border-bottom`} key={index}>
              <div className="d-grid gap-2 w-90">
                <div className="d-flex align-items-center gap-2">
                  <p className="small_text__14">When</p>
                  <Select
                    defaultValue="jack"
                    // onChange={handleChange}
                    size="large"
                    placeholder="Choose Condition"
                    options={[
                      { value: "jack", label: "First repsonse target" },
                      { value: "lucy", label: "Resolution target" },
                    ]}
                    className="w-25"
                  />
                  <p className="small_text__14">is not met, escalate</p>
                  <Select
                    defaultValue={["immediately"]}
                    // onChange={handleChange}
                    size="large"
                    placeholder="Select Time"
                    options={[
                      { value: "immediately", label: "Immediately" },
                      { value: "jack", label: "After 30 minutes" },
                      { value: "jack", label: "After 1 hour" },
                      { value: "jack", label: "After 2 hours" },
                      { value: "jack", label: "After 4 hours" },
                      { value: "jack", label: "After 8 hours" },
                      { value: "jack", label: "After 12 hours" },
                      { value: "jack", label: "After 1 day" },
                      { value: "jack", label: "After 2 days" },
                      { value: "jack", label: "After 3 days" },
                      { value: "jack", label: "After 1 week" },
                      { value: "jack", label: "After 2 weeks" },
                      { value: "jack", label: "After 1 month" },
                    ]}
                    className="w-25"
                  />
                </div>
                <div className="d-flex align-items-center gap-2">
                  <p className="small_text__14 text-nowrap">to</p>
                  <Select
                    // defaultValue={["lucy"]}
                    mode="multiple"
                    size="large"
                    // onChange={handleChange}
                    placeholder="Select one or more values"
                    options={[
                      { value: "jack", label: "Jack" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "yiminghe" },
                    ]}
                    className="w-100"
                  />
                </div>
                <Alert
                  showIcon
                  message="Escalations won't be sent for High, Medium, Low priority tickets."
                  type="warning"
                  className="w-fit-content"
                />
              </div>
              {getValue(props, "request.sla_conditions.length", 0) > 1 && (
                <Trash2
                  size={18}
                  className="delete-sla-condition"
                  onClick={() => props.deleteConditionArray(index)}
                />
              )}
              {getValue(props, "request.sla_conditions.length", 0) > 1 &&
                index !==
                  getValue(props, "request.sla_conditions.length", 0) - 1 && (
                  <div className="and-condition-button border">AND</div>
                )}
            </div>
          )
        )}
        <div
          className="header_blue_text__15 d-flex align-items-center gap-1 cursor-pointer px-4 py-3"
          onClick={() => props.handleAddCondition()}
        >
          <PlusCircle size={18} /> Add New Escalation
        </div>
      </div>
    </div>
  );
};

export default SLAViolationCondition;
