import MainLayout from "@layouts/HomeLayout/NewLayout";
import React, { useState } from "react";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { Alert, Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { Plus } from "lucide-react";
import ListLoader from "@components/common/Loader";
import SettingsPageDetails from "@components/Pages/PageDescription/SettingsPageDetails";
import "./sla-policy.scss";
import { slaPolicyDetails } from "@common/Data/page-details-data";

const SLAPolicy = ({ props }: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  return (
    <MainLayout {...props}>
      <CompanyProfileSubHeader
        addChildren={
          <Button
            type="primary"
            size="large"
            icon={<Plus size={20} />}
            onClick={() =>
              navigate(
                `/${getValue(params, "orgId", "")}/settings/sla-policy/create`
              )
            }
          >
            New SLA Policy
          </Button>
        }
      />
      <CompanyProfileDetailsLayout classname="my-0">
        {isLoading ? (
          <ListLoader />
        ) : (
          <div className="sla-policy-wrapper">
            <div className="w-70 p-4 sla-policy-card-container">
              <h6 className="header_text__18 mb-2">SLA Policies</h6>
              <p className="w-70 small_text__14 pb-4">
                Service Level Agreement(SLA) policies help you setup and
                maintain targets for the duration within which your teams
                respond and resolve tickets.
              </p>
              <Alert
                type="info"
                showIcon
                message="
                The first matching SLA policy will be applied to tickets with
                matching conditions"
                className="w-fit-content"
              />
            </div>
            <div className="w-30 p-4 sla-policy-right-details">
              <SettingsPageDetails data={slaPolicyDetails} />
            </div>
          </div>
        )}
      </CompanyProfileDetailsLayout>
      ;
    </MainLayout>
  );
};

export default SLAPolicy;
