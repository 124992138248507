import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import DashboardMoreOptions from "../../components/dashboard-more-options";

// Register the necessary components
ChartJS.register(
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
);

function DynamicAreaChartComponent(props: any) {
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });
  const [isStacked, setIsStacked] = useState(false);

  useEffect(() => {
    const children = getValue(props, "graph", []);
    if (children && children.length > 0) {
      // Extract x and y values directly from the children
      // const datasets = children.map((child: any, index: number) => ({
      //   label: getValue(child, "key", `Dataset ${index + 1}`),
      //   data: [
      //     {
      //       x: getValue(child, "key", ""),
      //       y: getValue(child, "count", 0),
      //     },
      //   ],
      //   backgroundColor: `rgba(${75 + index * 30}, 192, 192, 0.5)`,
      //   borderColor: `rgba(${75 + index * 30}, 192, 192, 1)`,
      //   fill: true, // Fill the area under the line
      // }));

      const datasets = [
        {
          data: children.map((child: any, index: number) =>
            getValue(child, "count", 0)
          ),
          labels: children.map((child: any, index: number) =>
            typeof getValue(child, "key", "") === "object"
              ? `${getValue(child, "key.first_name", "")} ${getValue(
                  child,
                  "key.last_name",
                  ""
                )}`
              : getValue(child, "key", "") ||
                "Unknown" ||
                `Category ${index + 1}`
          ),
          label: "",
          borderWidth: 2,
          fill: false, // Enables the fill for the area
          tension: 0.4, // Adds a slight curve to the line
        },
      ];

      // Use unique keys from children as labels
      const labels = children.map((item: any) =>
        Object.keys(getValue(item, "key", "")).length > 0
          ? `${getValue(item, "key.first_name", "")} ${getValue(
              item,
              "key.last_name",
              ""
            )}`
          : getValue(item, "key", "") || "Unknown" || `Category`
      );

      setData({
        labels: labels,
        datasets: datasets,
      });
    }
  }, [props.graph]);

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          color: "rgba(200, 200, 200, 0.2)",
        },
        beginAtZero: true,
      },
    },
    // onClick: (event: any, elements: any) => {
    //   if (elements.length > 0) {
    //     const datasetIndex = elements[0].datasetIndex;
    //     const dataIndex = elements[0].index;

    //     const key_value = data.labels[dataIndex];
    //     const child_key_value = data.datasets[datasetIndex].data[dataIndex];
    //     // Pass the data to the toggleChartDetails function
    //     props.toggleChartDetails({
    //       id: getValue(props, "id", ""),
    //       name: getValue(props, "name", ""),
    //       key_value,
    //       child_key_value,
    //     });
    //   }
    // },
  };

  return (
    <div
      className={`${
        getValue(props, "isMaximized", "") ? "" : "dashboard_border"
      }`}
    >
      <p
        className="header_text__16 pb-3 cursor-pointer text-decoration-underline"
        onClick={() => props.toggleChartDetails(props)}
      >
        {getValue(props, "name", "")}
      </p>
      <div className="cursor-pointer">
        <div
          className={`${
            getValue(props, "isMaximized", "")
              ? "maximized-chart-container"
              : "chart-holder"
          }`}
        >
          <Line data={data} options={options} />
        </div>
      </div>
      {!props.hideOptions && (
        <DashboardMoreOptions
          props={props}
          id={getValue(props, "id", "")}
          confirmDeleteAnalyticsComponent={
            props.confirmDeleteAnalyticsComponent
          }
          handleEditChart={props.handleEditChart}
        />
      )}
    </div>
  );
}

export default DynamicAreaChartComponent;
