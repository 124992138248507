import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllSLAPOlicy = (queryRequest: string) =>
  get(`${config.API_URL}/sla-policy?${queryRequest}`);

export const getSpecificSLAPOlicy = (id: string) =>
  get(`${config.API_URL}/sla-policy/${id}`);

export const createSLAPOlicy = (payload: object) =>
  post(`${config.API_URL}/sla-policy`, payload);

export const updateSLAPOlicy = (id: string, payload: object) =>
  patch(`${config.API_URL}/sla-policy/${id}`, payload);

export const deleteSLAPOlicy = (id: string) =>
  Delete(`${config.API_URL}/sla-policy/${id}`);
