import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import DashboardMoreOptions from "../../components/dashboard-more-options";

// Register the necessary components
ChartJS.register(ArcElement, Tooltip, Legend);

function PieChartComponent(props: any) {
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const graphData = getValue(props, "graph", []);

    if (graphData.length > 0) {
      const colorPalette = [
        "#6a98cc",
        "#ffa600",
        "#bb5dfb",
        "#00a887",
        "#f95d6a",
      ];

      const dynamicColors = graphData.map(
        (_: any, index: any) => colorPalette[index % colorPalette.length]
      );

      const labels = graphData.map((item: any) =>
        typeof getValue(item, "key", "") === "object"
          ? `${getValue(item, "key.first_name", "")} ${getValue(
              item,
              "key.last_name",
              ""
            )}`
          : getValue(item, "key", "")
      );
      const dataValues = graphData.map((item: any) =>
        getValue(item, "count", 0)
      );
      const label = getValue(props, "module_field.label", "");

      setData({
        labels: labels,
        datasets: [
          {
            label: label,
            data: dataValues,
            backgroundColor: dynamicColors,
            borderColor: "#fff",
            borderWidth: 1,
          },
        ],
      });
    }
  }, [props.graph, getValue(props, `module_field.label`, "")]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      datalabels: {
        color: "#fff",
      },
    },
  };

  return (
    <div
      className={`${
        getValue(props, "isMaximized", "") ? "" : "dashboard_border"
      }`}
    >
      <p
        className="header_text__16 pb-3 cursor-pointer text-decoration-underline"
        onClick={() => props.toggleChartDetails(props)}
      >
        {getValue(props, "name", "")}
      </p>
      <div
        className={`${
          getValue(props, "isMaximized", "")
            ? "pie-chart-holder"
            : "chart-holder"
        }`}
      >
        <Pie data={data} options={options} className="pb-4 pe-3" />
      </div>
      {!props.hideOptions && (
        <DashboardMoreOptions
          props={props}
          id={getValue(props, "id", "")}
          confirmDeleteAnalyticsComponent={
            props.confirmDeleteAnalyticsComponent
          }
          handleEditChart={props.handleEditChart}
        />
      )}
    </div>
  );
}

export default PieChartComponent;
