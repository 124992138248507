import { convertCurrentDate } from "@common/date-helpers";
import { getValue } from "@utils/lodash";
import React from "react";
import DashboardMoreOptions from "../../components/dashboard-more-options";

function KPIRankingsComponents(props: any) {
  return (
    <div
      className={`${
        getValue(props, "isMaximized", "") ? "" : "dashboard_border"
      }`}
    >
      <p
        className="header_text__16 pb-3 cursor-pointer"
        onClick={() => props.toggleChartDetails(props)}
      >
        {getValue(props, `name`, "")}
      </p>
      <table className="w-100">
        {/* <thead>
          <th></th>
          <th></th>
          <th></th>
        </thead> */}
        {getValue(props, `graph`, []).map((item: object, i: number) => {
          return (
            <tr
              className={`w-100 pb-3 ${
                i !== getValue(props, `graph`, []).length - 1
                  ? "border-bottom"
                  : ""
              }`}
            >
              <td className="w-10">{i + 1}.</td>
              <td className="w-50">
                {convertCurrentDate(getValue(item, `key.created_at`, ""))}
              </td>
              <td className="w-20 text-center">
                {getValue(item, `count`, "")}
              </td>
              <td className="w-20">
                {getValue(item, `percentageToCurrent`, "")}
              </td>
            </tr>
          );
        })}
      </table>
      <DashboardMoreOptions
        props={props}
        id={getValue(props, "id", "")}
        confirmDeleteAnalyticsComponent={props.confirmDeleteAnalyticsComponent}
        handleEditChart={props.handleEditChart}
      />
    </div>
  );
}

export default KPIRankingsComponents;
