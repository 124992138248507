import MainLayout from "@layouts/HomeLayout/NewLayout";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { Plus } from "lucide-react";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import BackButton from "@components/custom/BrowserBack";
import { useEffect, useState } from "react";
import InputRuleForm from "@components/InputRuleForm/form";
import { handleTextChange } from "@common/handleChange";
import FormDescription from "@components/custom/FormDescrption/FormDescription";
import SLATargetTable from "./components/SLATargetTable";
import SLATicketCondition from "./components/SLATicketCondition";
import SLAViolationCondition from "./components/SLAViolationCondition";
import SLADueTime from "./components/SLADueTime";
import { toast } from "sonner";
import {
  createSLAPOlicy,
  updateSLAPOlicy,
} from "@services/sla-policy.services";
import { getSpecificModule } from "@services/modules.service";
import { getModuleFields } from "@services/module-fields.service";

const CreateSLAPolicy = ({ props }: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const [editId, seteditId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState({
    name: "",
    description: "",
    sla_conditions: [
      {
        module_field: "",
        value: [],
      },
    ],
  });

  const [slaTarget, setSlaTarget] = useState({
    priority_1: {
      first_response_time: "/PT15M/",
      every_response_time: "",
      resolution_due_time: "/PT15M/",
      business_hours: true,
      escalation_enabled: true,
    },
    priority_2: {
      first_response_time: "/PT15M/",
      every_response_time: "",
      resolution_due_time: "/PT15M/",
      business_hours: true,
      escalation_enabled: true,
    },
    priority_3: {
      first_response_time: "/PT15M/",
      every_response_time: "",
      resolution_due_time: "/PT15M/",
      business_hours: true,
      escalation_enabled: true,
    },
    priority_4: {
      first_response_time: "/PT15M/",
      every_response_time: "",
      resolution_due_time: "/PT15M/",
      business_hours: true,
      escalation_enabled: true,
    },
  });

  const [slaDueTime, setSlaDueTime] = useState<any>({});

  const [slaViolated, setSlaViolated] = useState({
    response: {
      escalation_time: 0,
      agents: [],
    },
    resolution_level_1: {
      escalation_time: 0,
      agents: [],
    },
    resolution_level_2: {
      escalation_time: 0,
      agents: [],
    },
    resolution_level_3: {
      escalation_time: 0,
      agents: [],
    },
    resolution_level_4: {
      escalation_time: 0,
      agents: [],
    },
    next_response: {
      escalation_time: 0,
      agents: [],
    },
  });

  const [moduleInfo, setModuleInfo] = useState({});
  const [moduleFields, setModuleFields] = useState([]);
  const [isModuleInfoLoading, setModuleInfoLoading] = useState(false);
  const [isModuleFieldsLoading, setModuleFieldsLoading] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                            useEffect Section                               */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getModuleInfo();
  }, []);

  useEffect(() => {
    getTicketFields();
  }, [moduleInfo]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      let payload = JSON.parse(JSON.stringify(request));
      const resp = editId
        ? await updateSLAPOlicy(editId, payload)
        : await createSLAPOlicy(payload);

      if (resp) {
        toast.success("Created Successfully");

        // setRequest({
        //   name: "",
        //   description: "",
        //   hours: [],
        //   holidays: [],
        // });
        // navigate(`/${getValue(params, "orgId", "")}/settings/business-hours`);
      } else {
        toast.error(`Failed to ${editId ? "editing" : "creating"} SLA POlicy`);
      }
    } catch (error) {
      toast.error(`Error ${editId ? "editing" : "creating"} SLA POlicy`);
    } finally {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Tickets Section                              */
  /* -------------------------------------------------------------------------- */

  const getModuleInfo = async () => {
    try {
      setModuleInfoLoading(true);
      let resp = await getSpecificModule("tickets");
      if (resp) {
        setModuleInfo(getValue(resp, `data`, {}));
        setModuleInfoLoading(false);
      } else {
        setModuleInfoLoading(false);
      }
    } catch (error) {
      setModuleInfoLoading(false);
    }
  };

  const getTicketFields = async () => {
    if (getValue(moduleInfo, "id", "")) {
      try {
        setModuleFieldsLoading(true);
        let resp = await getModuleFields(getValue(moduleInfo, "id", ""));
        if (resp) {
          // const updatedResp= getValue(resp, `data.module_fields`, []).filter((item: object) =>
          setModuleFields(
            getValue(resp, `data.module_fields`, []).filter((item: object) =>
              getValue(item, `form_default`, false)
            )
          );
          // setMainFields(
          //   getValue(resp, `data.module_fields`, []).filter(
          //     (item: object) => !getValue(item, `required`, false)
          //   )
          // );
          setModuleFieldsLoading(false);
        } else {
          setModuleFieldsLoading(false);
        }
      } catch (error) {
        setModuleFieldsLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Other Section                              */
  /* -------------------------------------------------------------------------- */
  const handleAddCondition = () => {
    setRequest((request) => ({
      ...request,
      sla_conditions: [
        ...request.sla_conditions,
        {
          module_field: "",
          value: [],
        },
      ],
    }));
  };

  const deleteConditionArray = (index: number) => {
    setRequest((request) => ({
      ...request,
      sla_conditions: request.sla_conditions.filter((_, i) => i !== index),
    }));
  };

  // console.log("request", request);

  return (
    <MainLayout {...props}>
      <CompanyProfileSubHeader
        addChildren={
          <Button
            type="primary"
            size="large"
            icon={<Plus size={20} />}
            onClick={() => handleSubmit()}
            loading={isLoading}
            disabled={!getValue(request, "name", "")}
          >
            Create SLA Policy
          </Button>
        }
      />
      <CompanyProfileDetailsLayout classname="my-0 p-4">
        <h6 className="header_text__18 d-flex align-items-center gap-3 mb-4">
          <BackButton className="" />
          {editId ? "Edit" : "New"} SLA Policy
        </h6>
        <div className="w-50">
          <InputRuleForm
            inputType="TEXT"
            name="name"
            value={getValue(request, "name", "")}
            placeholder="Enter Name"
            required
            label="Name (For Own Reference)"
            onChange={(e: any) => handleTextChange(e, request, setRequest)}
          />
        </div>
        <FormDescription
          request={request}
          textClassname="mb-2 mt-3"
          inputClassname="w-50"
          input={
            <InputRuleForm
              inputType="TEXTAREA"
              name="description"
              value={getValue(request, `description`, "")}
              placeholder="e.g. This Business Calender belongs to Chicago timezone"
              label="Description"
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
            />
          }
        />
        <SLATicketCondition
          request={request}
          setRequest={setRequest}
          handleAddCondition={handleAddCondition}
          deleteConditionArray={deleteConditionArray}
          moduleFields={moduleFields}
        />
        <SLATargetTable request={slaTarget} setRequest={setSlaTarget} />
        <SLADueTime request={slaDueTime} setRequest={setSlaDueTime} />
        <SLAViolationCondition
          request={request}
          handleAddCondition={handleAddCondition}
          deleteConditionArray={deleteConditionArray}
        />
      </CompanyProfileDetailsLayout>
      ;
    </MainLayout>
  );
};

export default CreateSLAPolicy;
