import MainLayout from "@layouts/HomeLayout/NewLayout";
import { useEffect, useState } from "react";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import CannedFolders from "./components/canned-folders";
import CannedTable from "./components/canned-table";
import "./canned.scss";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  createCannedResponse,
  createCannedResponseFolder,
  deleteCannedResponse,
  deleteCannedResponseFolder,
  getAllCannedFolderTableResponse,
  getAllCannedResponseFolders,
  updateCannedResponse,
  updateCannedResponseFolder,
} from "@services/canned-response.service";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import ListLoader from "@components/common/Loader";
import { Button, Modal } from "antd";
import InputRuleForm from "@components/InputRuleForm/form";
import { Info, Plus } from "lucide-react";
import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import SubmitContainer from "@components/common/ButtonContainer/SubmitContainer";
import CannedResponseDetails from "./components/canned-response-editor";

type Attachment = {
  id: any;
};

const CannedResponse = ({ props }: any) => {
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState({ name: "" });
  const [editId, setEditID] = useState("");
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [tableData, setTableData] = useState({});
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isCannedSliderOpen, setCannedSliderOpen] = useState(false);
  const [isCannedSliderOpen1, setCannedSliderOpen1] = useState(false);
  const [cannedEditId, setCannedEditId] = useState("");
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  // const [totalFolderCount, setTotalFolderCount] = useState(0);
  // const [folderLimit, setFolderLimit] = useState(2);
  // const [folderPageNo, setFolderPageNo] = useState(1);

  const [cannedRequest, setCannedRequest] = useState<{
    title: string;
    content: string;
    visibility: string;
    folder: string;
    attachments: Attachment[];
    groups: any[];
  }>({
    title: "",
    content: "",
    visibility: "myself",
    folder: "",
    attachments: [],
    groups: [],
  });
  const [personalFolderId, setPersonalFolderId] = useState("");
  const [filteredFolderList, setFilteredFolderList] = useState<any[]>([]);
  const handleOpenSlider = (id?: string) => {
    if (id) {
      setCannedEditId(id);
    }
    setCannedSliderOpen(true);
  };

  const handleCannedSlider = () => {
    setCannedSliderOpen(!isCannedSliderOpen);
    setCannedRequest({
      title: "",
      content: "",
      visibility: "myself",
      folder: "",
      attachments: [],
      groups: [],
    });
    setCannedEditId("");
  };

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getCannedResponseList(true);
  }, []);

  // useEffect(() => {
  //   if (Object.keys(UrlParams).length > 0) {
  //     if (UrlParams.folderPageNo) {
  //       setFolderPageNo(parseInt(UrlParams.folderPageNo));
  //     }
  //     if (UrlParams.folderLimit) {
  //       setFolderLimit(parseInt(UrlParams.folderLimit));
  //     }
  //     getCannedResponseList(true);
  //   }
  // }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getCannedResponseList = async (status: boolean) => {
    try {
      setIsLoading(status);
      const payload = {
        page_size: 100,
        page_no: 1,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllCannedResponseFolders(queryRequest);
      if (resp) {
        // setFolderLimit(getValue(resp, "data.pagination.page_count", 0));
        // setTotalFolderCount(getValue(resp, "data.pagination.total", 0));
        const folders = getValue(resp, "data.folders", []);
        setList(getValue(resp, "data", []));
        if (getValue(resp, "data", [].length > 0)) {
          setSelectedFolder(getValue(resp, "data.folders[0].id", ""));
        }
        const personalFolder = folders.find(
          (folder: any) => folder.is_personal === true
        );
        if (personalFolder) {
          setPersonalFolderId(personalFolder.id);
        }
        const nonPersonalFolders = folders.filter(
          (folder: any) => folder.is_personal !== true
        );
        setFilteredFolderList(
          getValue(nonPersonalFolders, `length`, 0) > 0
            ? filteredFolderList.map((item: object) => ({
                ...item,
                value: getValue(item, `id`, ""),
                label: getValue(item, `name`, ""),
              }))
            : []
        );
      }
    } catch (error) {
      toast.error("Failed to load canned responses.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setConfirmLoading(true);
    try {
      const payload: { name: string; id?: string } = { name: request.name };
      const resp = await createCannedResponseFolder(payload);
      if (resp) {
        toast.success("Created Successfully");
        setOpen(false);
        getCannedResponseList(true);
        setRequest({
          name: "",
        });
      } else {
        toast.error("Failed to create folder");
      }
    } catch (error) {
      toast.error("Error creating folder");
    } finally {
      setConfirmLoading(false);
      setCreateLoading(false);
    }
  };

  const handleFolderUpdate = async () => {
    setConfirmLoading(true);
    try {
      const payload = { ...request };
      const resp = await updateCannedResponseFolder(editId, payload);
      if (resp) {
        toast.success("Updated Successfully");
        setOpen(false);
        getCannedResponseList(true);
        setRequest({
          name: "",
        });
      } else {
        toast.error("Failed to create folder");
      }
    } catch (error) {
      toast.error("Error creating folder");
    } finally {
      setConfirmLoading(false);
      setCreateLoading(false);
    }
  };

  const handleDeleteModal = (id: string, name: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete this folder - ${name}?`,
      content:
        "Once deleted, this folder and its canned responses cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteCannedResponseFolder(id);
          toast.success("Folder deleted successfully");
          getCannedResponseList(true);
          getCannedTableList(true);
        } catch (error) {
          toast.error("Error deleting folder");
        }
      },
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                           Canned table Section                             */
  /* -------------------------------------------------------------------------- */
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const getCannedTableList = async (status: boolean) => {
    try {
      setIsTableLoading(status);
      const payload = {
        folder: getValue(UrlParams, "folder", "")
          ? getValue(UrlParams, "folder", "")
          : selectedFolder,
        page_size: getValue(UrlParams, `page_size`, "")
          ? getValue(UrlParams, `page_size`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        order_by: "created_at",
        order_type: "desc",
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllCannedFolderTableResponse(queryRequest);
      if (resp) {
        setTableData(getValue(resp, "data", []));
        setTotalCount(getValue(resp, "data.pagination.total", 0));
        setLimit(getValue(resp, "data.pagination.page_size", 0));
        setPage_no(getValue(resp, "data.pagination.page", 0));
      }
    } catch (error) {
      toast.error("Failed to load canned responses.");
    } finally {
      setIsTableLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const handleChangePagination = (page_no: any, limit: any) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setPage_no(page_no);
  };

  const handleCannedSubmit = async () => {
    try {
      setSubmitLoading(true);
      let payload = JSON.parse(JSON.stringify(cannedRequest));
      const resp = cannedEditId
        ? await updateCannedResponse(cannedEditId, payload)
        : await createCannedResponse(payload);

      if (resp) {
        toast.success(`${cannedEditId ? "Updated" : "Created"} Successfully`);
        setCannedSliderOpen(false);
        getCannedResponseList(true);
        getCannedTableList(true);
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                              Modal Section                             */
  /* -------------------------------------------------------------------------- */
  const showModal = () => {
    setEditID("");
    setRequest({ name: "" });
    setOpen(true);
  };

  const handleOk = () => {
    if (editId !== "") {
      handleFolderUpdate();
    } else {
      handleSubmit();
    }
  };

  const handleUpdateModal = (name: string, id: string) => {
    setRequest({ name });
    setEditID(id);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setRequest({
      name: "",
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                            Handle folder selection                         */
  /* -------------------------------------------------------------------------- */
  // const handlePaginationChange = (page: any, pageSize: any) => {
  //   setFolderPageNo(page);
  //   // setFolderLimit(pageSize);
  //   let payload = {
  //     folderPageNo: folderPageNo,
  //     // folderLimit: folderLimit,
  //   };
  //   let queryRequest = QueryRequestHelper(payload);
  //   navigate(`${window.location.pathname}?${queryRequest}`);
  // };

  const handleSelectFolder = (id: string) => {
    setSelectedFolder(id);
    let payload = {
      // ...UrlParams,
      folder: id,
      page_no: 1,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  useEffect(() => {
    if (UrlParams.folder || UrlParams.page_no || UrlParams.page_size) {
      getCannedTableList(true);
    }
    if (selectedFolder) {
      getCannedTableList(true);
    }
  }, [
    UrlParams.folder,
    UrlParams.page_no,
    UrlParams.page_size,
    selectedFolder,
  ]);

  const handleDeletCannedResponse = (id: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete this Canned response?`,
      content: "Once deleted, it cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteCannedResponse(id);
          toast.success("Response deleted successfully");
          getCannedResponseList(true);
        } catch (error) {
          toast.error("Error deleting folder");
        }
      },
    });
  };

  return (
    <MainLayout {...props}>
      <CompanyProfileSubHeader
        addChildren={
          <div className="d-flex align-items-center gap-3">
            <Info
              onClick={() => setCannedSliderOpen1(true)}
              size={18}
              className="cursor-pointer"
            />
            <Button
              type="primary"
              size="large"
              icon={<Plus size={20} />}
              onClick={() => handleOpenSlider()}
            >
              New Canned Response
            </Button>
          </div>
        }
      />
      <CompanyProfileDetailsLayout classname="my-0">
        {isLoading ? (
          <ListLoader />
        ) : (
          <div className="row w-100 m-0">
            <CannedFolders
              UrlParams={UrlParams}
              list={list}
              showModal={showModal}
              handleUpdateModal={handleUpdateModal}
              handleDeleteModal={handleDeleteModal}
              selectedFolder={selectedFolder}
              handleSelectFolder={handleSelectFolder}
              // folderLimit={folderLimit}
              // folderPageNo={folderPageNo}
              // totalFolderCount={totalFolderCount}
              // handlePaginationChange={handlePaginationChange}
            />
            <CannedTable
              list={list}
              tableData={tableData}
              isTableLoading={isTableLoading}
              handleDeletCannedResponse={handleDeletCannedResponse}
              page_no={page_no}
              limit={limit}
              totalCount={totalCount}
              handleChangePagination={handleChangePagination}
              handleOpenSlider={handleOpenSlider}
            />
          </div>
        )}
        <Modal
          title={`${
            getValue(request, "id", "") ? "Edit" : "Create New"
          } Folder`}
          open={open}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
        >
          <InputRuleForm
            inputType="TEXT"
            name="name"
            value={getValue(request, "name", "")}
            placeholder="Enter Folder Name"
            required
            label="Folder Name"
            onChange={(e: any) =>
              setRequest({
                ...request,
                name: e.target.value,
              })
            }
          />
        </Modal>
        <SlideFromRightPopup
          isPopupVisible={isCannedSliderOpen}
          addCustomClass={"rolls-popup"}
          title={`${cannedEditId ? "Edit" : " Create"} Canned Response`}
          toggle={handleCannedSlider}
          width={"620px"}
          permissions={getValue(props, `permissions`, [])}
        >
          <div className="subpipeline-wrapper p-4">
            <CannedResponseDetails
              request={cannedRequest}
              setRequest={setCannedRequest}
              personalFolderId={personalFolderId}
              filteredFolderList={filteredFolderList}
              selectedFolder={selectedFolder}
              editId={cannedEditId}
              setSubmitLoading={setSubmitLoading}
            />
          </div>
          <SubmitContainer
            handleBackButton={() => handleCannedSlider()}
            handleSubmit={() => handleSubmit()}
            // submitLoading={isSubmitLoading}
            first_title={"Cancel"}
            disabled={
              getValue(request, "title", "") === "" ||
              getValue(request, "content.length", "") < 12
            }
            third_title={"Save "}
            handleThirdButton={handleCannedSubmit}
            // second_title={isEdit ? "Update Filter" : "Save Filter"}
            permissions={getValue(props, `permissions`, [])}
          />
        </SlideFromRightPopup>
        <SlideFromRightPopup
          isPopupVisible={isCannedSliderOpen1}
          addCustomClass={"rolls-popup"}
          title={`Canned Responses`}
          toggle={() => setCannedSliderOpen1(false)}
          width={"420px"}
        >
          <div className="p-4">
            <p className="small_text__15">
              With Canned Responses, agents can use a predefined set of reply
              templates that they can send out with a single click. Creating
              canned responses can help avoid duplication of efforts, as agents
              can avoid repetitive typing of replies during customer
              conversations.
            </p>
            <p className="small_text__15 pt-3">
              For example, when a customer asks ’How to cancel my
              subscription?’, agents can simply insert the response template
              into their reply to improve their response time. You can even
              personalize canned responses by using placeholders that fill
              dynamic content like the requester’s name and ticket ID.
            </p>
          </div>
        </SlideFromRightPopup>
      </CompanyProfileDetailsLayout>
    </MainLayout>
  );
};

export default CannedResponse;
