export const businessHoursDetails = [
  {
    heading: "Business hours",
    description: `Business Hours give you more control over SLAs in your helpdesk, and when a ticket is due. For example, if your helpdesk works between 9am to 6pm Mon-Fri and a customer logs a ticket at 7pm on Tuesday, the "Due by" timers do not start ticking till Wednesday morning at 9.`,
  },
  {
    heading: "Holidays",
    description:
      "Holidays work exactly like business hours. If your helpdesk works between 9am to 6pm Mon-Fri and a customer sends a medium priority ticket at 8pm on Friday with a resolution time of 24 hours, then the ticket Due By time is set as 9am on Tuesday.(not 8pm on Sat)",
  },
  {
    heading: "Multiple Business Hours",
    description:
      "You can also create multiple sets of business hours and holidays, and apply them for specific groups. If you have one support team working on PST and another on GMT, you can let each group have its own set of business hours and holidays.",
  },
];

export const slaPolicyDetails = [
  {
    heading: "SLA policy",
    description: `A service level agreement (SLA) policy lets you set standards of performance for your support team. You can set SLA policies for the time within which agents should respond to, and resolve tickets based on ticket priorities. You can choose whether you want each SLA rule to be calculated over calendar hours or your business hours. Your SLA Policies will be used in Freshdesk to determine the “Due By” time for each ticket.`,
  },
  {
    heading: "Using Multiple SLA Policies",
    points: [
      `You can have multiple SLA policies, triggered by specific conditions like the customer who requested the ticket, the group it is assigned to, its source and/or product.`,
      `<b><i>The order of your SLA policies is important.</i></b>`,
      `The first SLA Policy that matches all conditions for a ticket will be applied to it, so remember to order your important rules closer to the top`,
    ],
  },
  {
    heading: "SLA reminders",
    points: [
      "You can set up reminders to make sure that agents are notified about the approaching due by time for tickets. The reminders can be for ticket response and resolution.",
      "The reminder email can contain contextual information like the URL of the ticket and ticket priority and can be configured in Admin > Email Notifications.",
    ],
  },
  {
    heading: "SLA violation notifications",
    points: [
      "You can also set up escalation rules that notify agents or managers when SLAs have been violated. You can set up multiple levels of escalation for resolution SLA.",
      "The violation emails can be configured in Admin > Email Notifications.",
    ],
  },
];
