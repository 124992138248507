import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import DashboardMoreOptions from "../../components/dashboard-more-options";

function ColumnChartComponent(props: any) {
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const graphData = getValue(props, "graph", []);
    if (graphData.length > 0) {
      const isStackedChart = graphData.some(
        (item: any) => item.child && item.child.length > 0
      );
      const labels = graphData.map((item: any) =>
        // typeof getValue(item, "key", "") === "object"
        Object.keys(getValue(item, "key", "")).length > 0
          ? `${getValue(item, "key.first_name", "")} ${getValue(
              item,
              "key.last_name",
              ""
            )}`
          : getValue(item, "key", "") || "Unknown"
      );

      const label = getValue(props, "module_field.label", "");

      const defaultColors = [
        "#1cb59a",
        "#fc5956",
        "#003366",
        "#0066CC",
        "#FFA500",
        "#800080",
      ];

      if (!isStackedChart) {
        const dataValues = graphData.map((item: any) =>
          getValue(item, "count", 0)
        );

        const dynamicColors = graphData.map(
          (_: any, index: any) => defaultColors[index % defaultColors.length]
        );

        setData({
          labels: labels,
          datasets: [
            {
              label: label,
              data: dataValues,
              backgroundColor: dynamicColors,
              borderColor: dynamicColors.map((color: any) =>
                color.replace("0.5", "1")
              ),
              borderWidth: 1,
            },
          ],
        });
      } else {
        const datasets = graphData.map((item: any, index: number) => ({
          label:
            Object.keys(getValue(item, "key", "")).length > 0
              ? `${getValue(item, "key.first_name", "")} ${getValue(
                  item,
                  "key.last_name",
                  ""
                )}`
              : getValue(item, "key", "") ||
                "Unknown" ||
                `Category ${index + 1}`,
          data: item.child.map((childItem: any) => childItem.count),
          backgroundColor: defaultColors[index % defaultColors.length],
          borderColor: defaultColors[index % defaultColors.length],
          borderWidth: 1,
        }));

        const childLabels = graphData[0].child.map((childItem: any) =>
          Object.keys(getValue(childItem, "key", "")).length > 0
            ? `${getValue(childItem, "key.first_name", "")} ${getValue(
                childItem,
                "key.last_name",
                ""
              )}`
            : getValue(childItem, "key", "") || "Unknown"
        );

        setData({
          labels: childLabels,
          datasets: datasets,
        });
      }
    }
  }, [props.graph, getValue(props, `module_field.label`, "")]);

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        color: "#000",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        stacked: !!data.datasets.length && data.datasets[0].data.length > 1,
        grid: {
          display: false,
        },
      },
      x: {
        stacked: !!data.datasets.length && data.datasets[0].data.length > 1,
        grid: {
          display: false,
        },
      },
    },
    barPercentage: 0.6,
  };

  return (
    <div
      className={`${
        getValue(props, "isMaximized", "") ? "" : "dashboard_border"
      }`}
    >
      <p
        className="header_text__16 pb-3 cursor-pointer text-decoration-underline"
        onClick={() => props.toggleChartDetails(props)}
      >
        {getValue(props, "name", "")}
      </p>
      <div
        className={`${
          getValue(props, "isMaximized", "")
            ? "maximized-chart-container"
            : "chart-holder"
        }`}
      >
        <Bar data={data} options={options} />
      </div>
      {!props.hideOptions && (
        <DashboardMoreOptions
          props={props}
          id={getValue(props, "id", "")}
          confirmDeleteAnalyticsComponent={
            props.confirmDeleteAnalyticsComponent
          }
          handleEditChart={props.handleEditChart}
        />
      )}
    </div>
  );
}

export default ColumnChartComponent;
